/** @jsx jsx */
import { jsx, Text } from "theme-ui";
// import { noop } from "sites-common/utils/lodash";
import { useQueryParam, StringParam } from "use-query-params";

import { useEffect, useState } from "react";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import { Stack } from "office-ui-fabric-react";
import fabricStyles from "sites-common/utils/fabricStyles";
import Records from "gatsby-plugin-hfn-profile/components/FirestoreCloudFunctions/download";
import { decode2 } from "../../../lib/encodedecode";
import DefaultLayout from "../../../layouts/dlw-mobile";
import {
  collection_userrecords_proxy,
  overall,
} from "../../../components/AshramOffice/KanhaVolunteering/adminconstants";

const KanhaVolunteering = () => {
  const [code] = useQueryParam("q", StringParam);
  const [d, setD] = useState({});
  const [error, setError] = useState("");
  const { srcmProfile } = useAuth();

  const { dept, title, oppy, aims_flds = [], visit_flds = [] } = d;

  useEffect(() => {
    try {
      const d1 = JSON.parse(decode2(srcmProfile.uid, code));
      setD(d1);
    } catch (e) {
      setError("Invalid Code");
    }
  }, [code, setError, setD, srcmProfile]);

  if (error) {
    return <DefaultLayout>{error}</DefaultLayout>;
  }
  return (
    <DefaultLayout>
      <Stack
        horizontal
        verticalAlign="center"
        sx={{
          bg: "darkblue",
          color: "white",
          mb: 2,
          borderRadius: 5,
          p: 2,
        }}
      >
        {title}
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <div>
          <Text variant="title">
            {dept !== overall ? dept : "All"} Volunteers
          </Text>
        </div>
        <Records
          dbcode={collection_userrecords_proxy}
          fields={[...aims_flds, ...visit_flds]}
          filters={[
            ["oppy", "==", oppy],
            dept !== overall && ["vlntr_area", "array-contains", dept],
          ].filter((x) => x)}
          styles={fabricStyles.buttonStyles.danger}
          text="Download CSV"
          iconProps={{ iconName: "Download" }}
        />
      </Stack>
    </DefaultLayout>
  );
};

export default KanhaVolunteering;
