import { Base64 } from "js-base64";

// algorithm version
// const evers = "e2";

const chr = (n) => String.fromCharCode(n);
const ord = (c) => c.charCodeAt(0);
export function encode1(key, string) {
  return Base64.encode(string);
}

export function decode1(key, encString) {
  return Base64.decode(encString);
}

function encode0(key, string) {
  let encodedChars = "";
  let i;
  for (i = 0; i < string.length; i += 1) {
    const keyC = key[i % key.length];
    const encodedC = chr((ord(string[i]) + ord(keyC)) % 256);
    encodedChars += encodedC;
  }
  const r = Base64.btoa(encodedChars);
  return r;
}

function decode0(key, encString) {
  let decodedChars = "";
  let i;
  const string = Base64.atob(encString);
  for (i = 0; i < string.length; i += 1) {
    const keyC = key[i % key.length];
    const decodedC = chr((ord(string[i]) - ord(keyC)) % 256);
    decodedChars += decodedC;
  }
  return decodedChars;
}

export function encode2(key, string) {
  return encode0(key, encode1(key, string));
}

export function decode2(key, string) {
  return decode1(key, decode0(key, string));
}

// const decode = (k, s) =>
//   evers === "e2"
//     ? decode2(k, s)
//     : evers === "e1"
//     ? decode1(k, s)
//     : decode0(k, s);
// const encode = (k, s) =>
//   evers === "e2"
//     ? encode2(k, s)
//     : evers === "e1"
//     ? encode1(k, s)
//     : encode0(k, s);
